<template>
  <div class="create-account px-6">
    <div class="content">
      <div class="position-rel width-full text-center">
        <Celebration></Celebration>
      </div>
      <div class="text-center f-s-18 f-w-700 content-title">Congratulations !</div>
      <div class="text-center py-6 content-subtitle">Your new payment schedule is in effect.</div>
    </div>
    <div class="footer">
      <wap-btn :height="50" width="100%" @click="onClickReturn">Return Home Page</wap-btn>
    </div>
  </div>
</template>

<script>
import { DataType, Tool } from '@/assets/js/util'
import WapBtn from '@/components/base/WapBtn'
import Celebration from '@/components/svg/Celebration'
const tool = new Tool()

export default {
  components: {
    WapBtn,
    Celebration
  },
  data: () => ({
    assist: {
      dataType: DataType,
      tool: tool
    },
    layouts: {
      data: {}
    }
  }),
  methods: {
    onClickReturn () {
      this.$router.push('/coupon/overview')
    }
  }
}
</script>

<style lang="sass" scoped>
.create-account
  height: calc(100% - 44px)
  padding: 16px 24px
  display: flex
  flex-direction: column
  justify-content: space-between

.content
  margin-top: 110px
  width: 100%

.content-title
  color: #000410
  font-family: Montserrat, Montserrat-Bold
  width: 100%

.content-subtitle
  font-size: 14px
  font-family: Montserrat, Montserrat-Regular
  font-weight: 400
  text-align: left
  color: #9ca0ab
  line-height: 18px
  width: 100%

.action-container
  display: flex
  justify-content: space-between
  padding: 24px 24px
</style>
